import { findCategoryById } from '@kesko/kespro-ui-common';
import React from 'react';
import * as R from 'ramda';
import { RadioGroup } from './RadioGroup.js';
import { Icon } from '../Icon.js';
import style from './ProductCategoryFilter.scss';
export const ProductCategoryFilter = ({ selectedCategory, productCategoryHitCounts, categoryHierarchy, selectCategory, isDropdownOpen, onDropdownToggled, }) => React.createElement("div", { className: style.productCategoryFilter, "data-isdropdownopen": isDropdownOpen },
    React.createElement("button", { className: style.productCategoryFilter__title, onClick: onDropdownToggled, "aria-label": isDropdownOpen ? 'Sulje kategoriasuodattimet' : 'Avaa kategoriasuodattimet', "aria-expanded": isDropdownOpen, "aria-controls": "Kategoriasuodattimet", onKeyDown: () => onDropdownToggled },
        React.createElement("h3", null, "Tuotekategoriat"),
        React.createElement(Icon, { type: isDropdownOpen ? 'chevron-up' : 'chevron-down', size: 24 })),
    isDropdownOpen &&
        React.createElement(RadioGroup, { ariaDescribedBy: "Kategoriasuodattimet", ariaLabel: "Tuotekategoriat", items: mapCategoryToRadioGroupItem(selectedCategory || categoryHierarchy, productCategoryHitCounts).filter((item) => item.hitCount && item.hitCount > 0), selectedItemId: selectedCategory ? selectedCategory.category.id : undefined, selectItem: (id) => {
                selectCategory(id === 'root' ? undefined : findCategoryById(categoryHierarchy, id));
            }, backButton: selectedCategory && selectedCategory.category.id !== 'root'
                ? { isDisplayed: true, onClick: () => { selectCategory(selectedCategory.parent); } }
                : undefined }));
function mapCategoryToRadioGroupItem(category, categoryHitCounts) {
    if (category.children && category.children.length) {
        return [
            {
                id: category.category.id,
                name: 'Kaikki',
                group: category.category.name,
                hitCount: getCategoryHitCount(categoryHitCounts)(category),
            },
            ...category.children.map((child) => ({
                id: child.category.id,
                name: child.category.name,
                group: category.category.name,
                hitCount: getCategoryHitCount(categoryHitCounts)(child),
            })),
        ];
    }
    else {
        return [
            {
                id: category.category.id,
                name: 'Kaikki',
                group: category.category.name,
                hitCount: getCategoryHitCount(categoryHitCounts)(category),
            },
        ];
    }
}
function getCategoryHitCount(hits) {
    return (category) => {
        if (category.category.level >= 3) {
            const hit = R.find((h) => h.categoryId === category.category.id, hits);
            return hit ? hit.hits : 0;
        }
        else {
            return R.sum(category.children.map(getCategoryHitCount(hits)));
        }
    };
}

import React from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { setIsLoggedOut } from '../../reducers/authSlice.js';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './AppMenu.scss';
export const AppMenu = (props) => {
    const dispatch = useAppDispatch();
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const { children, className, isMobile, buttonCaption } = props;
    React.useEffect(() => {
        const authChannel = new BroadcastChannel('auth-channel');
        authChannel.onmessage = (message) => {
            if (message === 'logout') {
                dispatch(setIsLoggedOut({ showLoginModal: false, justLoggedOut: false }));
            }
        };
    }, []);
    return (React.createElement("div", { onClick: () => {
            if (isMobile) {
                window.postMessage('openWidget', '*');
            }
        } },
        React.createElement("div", { className: combineClassNames(className, isMobile ? styles.mobileContainer : styles.container, !isLoggedIn ? styles.hidden : '') }, isMobile && React.createElement("div", { className: styles.containerText }, buttonCaption)),
        isLoggedIn
            ? React.createElement(React.Fragment, null)
            : children));
};

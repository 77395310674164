import React from 'react';
import * as R from 'ramda';
import { WarehousingType, } from '@kesko/kespro-product-service-api';
import { selectClassNames } from '../../../util/css/classNames.js';
import { Accordion } from '../../../components/Accordion.js';
import { HorizontalContainer, VerticalContainer } from '../../../components/FlexboxContainer.js';
import { Icon } from '../../../Icon.js';
import styles from './style.scss';
export const ProductInfoContainer = ({ product, collapsed, toggleCollapse }) => (React.createElement(Accordion, { collapsed: collapsed, id: "product-details", "aria-label": "Toggle product details", title: "Tuotetiedot", toggleCollapse: toggleCollapse },
    renderFrozenInfo(product),
    renderProductInfo(product)));
function renderFrozenInfo(product) {
    return product.warehousingType === WarehousingType.Frozen ? (React.createElement("div", null,
        React.createElement("div", { className: styles.productClassIcon },
            React.createElement(Icon, { type: 'pakasteet', size: 20 })),
        React.createElement("p", { className: styles.productClassType }, "Pakasteet"))) : ([]);
}
function renderProductInfo(product) {
    const ingredients = { name: 'ainesosat', value: cleanupIngredients(product.ingredients) };
    const allergens = { name: 'allergeenit', value: normalizeArray(product.allergens) };
    const items = [
        { name: 'ean', value: normalize(product.ean) },
        // There is a zero-width space after the slash, to enable line breaking at that position
        // eslint-disable-next-line no-irregular-whitespace
        { name: 'valmistus-/​alkuperämaa', value: normalize(product.countryOfOrigin) },
        { name: 'valmistuttaja', value: normalize(product.producer) },
        { name: 'perusyksikön määrä', value: getAmountInBaseUnits(product) },
        { name: 'sap', value: normalize(trimLeadingZeros(product.id)) },
        { name: 'valmistaja', value: normalize(product.vendor) },
        { name: 'valmistusohje', value: normalizeArray(product.preparationProposals) },
        { name: 'säilyvyysaika avauksesta', value: formatShelfLife(product.shelfLife) },
        { name: 'merkki', value: normalize(product.brand) },
        { name: 'tietoa koostumuksesta', value: formatCompositionInfo(product.contentPercentages) },
        { name: 'tarkemmat koostumustiedot', value: normalizeArray(product.ingredientOrigins) },
        { name: 'ravitsemukselliset ominaisuudet', value: normalizeArray(product.nutritionalProperties) },
        { name: 'ravintosisältö', value: formatNutritionalContent(product.nutritionalContent) },
        { name: 'säilytys ja käyttöohje', value: normalize(product.instructions) },
        { name: 'tuotteen lisätiedot', value: formatAdditionalData(product.labels, product.additionalInstructions) },
        { name: 'varoitukset ja turvallisuus', value: normalizeArray(product.safetyInfo) },
    ];
    if (product.alcoholDetails) {
        items.push({ name: 'alkoholijuoman tiedot', value: formatAlcoholDetails(product.alcoholDetails) });
    }
    return (React.createElement(HorizontalContainer, { wrap: true, horizontalAlign: "flex-start", verticalAlign: "flex-start", className: styles.productInfo },
        React.createElement(HorizontalContainer, { horizontalAlign: "flex-start", wrap: true, className: styles.fullWidth },
            renderProductInfoItem(ingredients, styles.productInfoItemWide),
            renderProductInfoItem(allergens, styles.productInfoItemWide)),
        renderProductInfoItems(items)));
}
function normalize(value) {
    return [value ? { value } : { value: '-' }];
}
function normalizeArray(value) {
    return value === undefined || value.length === 0 ? [{ value: '-' }] : value.map((v) => ({ value: v }));
}
function cleanupIngredients(ingredients) {
    const unnecessaryPartOfString = 'Ainesosat: ';
    if (ingredients === undefined || ingredients === '') {
        return [{ value: '-' }];
    }
    else {
        return [{ value: ingredients.replace(unnecessaryPartOfString, '') }];
    }
}
function getAmountInBaseUnits(product) {
    const amount = R.view(R.lensPath(['packagingSize', 'amountInBaseUnits']), product);
    const unit = R.view(R.lensPath(['packagingSize', 'unit']), product) || '';
    return [{ value: amount === undefined ? '-' : `${amount} ${unit}` }];
}
function trimLeadingZeros(value) {
    return value.replace(/^0*/, '');
}
function formatShelfLife(shelfLife) {
    return shelfLife && shelfLife.fromOpening && shelfLife.fromOpening.value && shelfLife.fromOpening.unit
        ? [{ value: `${shelfLife.fromOpening.value} ${formatShelfLifeUnit(shelfLife.fromOpening.unit)}` }]
        : [{ value: '-' }];
}
function formatShelfLifeUnit(unit) {
    if (unit === 'Päivä') {
        return 'pv';
    }
    if (unit === 'Kuukausi') {
        return 'kk';
    }
    return unit;
}
function formatCompositionInfo(contentPercentages = []) {
    return normalizeArray(contentPercentages.filter((cp) => cp.percentage > 0).map((cp) => `${cp.name}: ${cp.percentage}%`));
}
const formatNutrientPrecision = (precision) => precision !== undefined && precision === 'LESS_THAN' ? '< ' : '';
const formatNutrientContent = ({ amount, precision }) => amount !== undefined ? `${formatNutrientPrecision(precision)}${amount.toString()}` : '';
function formatNutritionalContent(n) {
    if (!n) {
        return [];
    }
    const mappers = [
        ['fat', (value) => ({ value: `Rasva ${formatNutrientContent(value)} g` })],
        ['fatSaturated', (value) => ({ value: `josta tyydyttynyttä ${formatNutrientContent(value)} g`, indent: true })],
        ['carbohydrates', (value) => ({ value: `Hiilihydraatit ${formatNutrientContent(value)} g` })],
        ['sugar', (value) => ({ value: `josta sokereita ${formatNutrientContent(value)} g`, indent: true })],
        ['fiber', (value) => ({ value: `Ravintokuitu ${formatNutrientContent(value)} g` })],
        ['protein', (value) => ({ value: `Proteiini ${formatNutrientContent(value)} g` })],
        ['salt', (value) => ({ value: `Suola ${formatNutrientContent(value)} g` })],
        ['lactose', (value) => ({ value: `Laktoosi ${formatNutrientContent(value)} g` })],
    ];
    const applyIfHasAmount = applyWithPredicate((v) => v.amount !== undefined);
    return removeUndefineds([
        applyIfBothNotUndefined((kj, kcal) => ({ value: `Energia ${kj} kJ / ${kcal} kcal` }), R.path(['energyKj', 'amount'], n), R.path(['energyKcal', 'amount'], n)),
        ...mappers.map(([key, mapper]) => applyIfHasAmount(mapper, n[key])),
    ]);
    function applyIfBothNotUndefined(func, a, b) {
        if (a === undefined || b === undefined) {
            return undefined;
        }
        return func(a, b);
    }
    function applyWithPredicate(predicate) {
        return (mapper, value) => isNotUndefined(value) && predicate(value) ? mapper(value) : undefined;
    }
    function removeUndefineds(xs) {
        // Type coercion hack. With a newer typescript the filter function would notice we are using a type guard as the predicate, and adjust the return type accordingly
        return xs.filter(isNotUndefined);
    }
    function isNotUndefined(x) {
        return x !== undefined;
    }
}
function formatAlcoholDetails(a) {
    const mappers = [
        ['type', (value) => ({ name: 'type', value: `Tyyppi: ${normalizeValue([value])}` })],
        ['recommendation', (value) => ({ name: 'recommendation', value: `Suositeltu käyttöyhteys: ${value}` })],
        ['grape', (value) => ({ name: 'grape', value: `Rypäle: ${normalizeValue(value.split(' '))}` })],
        ['region', (value) => ({ name: 'region', value: `Tuotantoalue: ${normalizeValue(value.split(' '))}` })],
        ['taste', (value) => ({ name: 'taste', value: `Maku: ${normalizeValue([value])}` })],
        ['stopper', (value) => ({ name: 'stopper', value: `Suljenta: ${value}` })],
        ['color', (value) => ({ name: 'color', value: `Väri: ${normalizeValue([value])}` })],
    ];
    return mappers.filter(([key]) => a[key] !== undefined).map(([key, mapper]) => mapper(a[key]));
    function normalizeValue(value) {
        return value
            .map((component) => `${component.toLowerCase().charAt(0).toUpperCase()}${component.slice(1).toLowerCase()}`)
            .join(' ');
    }
}
// Combines data from two different arrays to one field in UI (labels and additional information)
function formatAdditionalData(l, additionalInformation) {
    additionalInformation = additionalInformation || [];
    if (l.length <= 0 && additionalInformation.length <= 0) {
        return [{ value: '-' }];
    }
    else {
        const labelArray = l.length ? l.map((label) => ({ value: label.name })) : [];
        if (labelArray.length > 0) {
            labelArray.push({ value: ' ' });
        } // makes new line after label data
        const InfoArray = additionalInformation.map((addInfo) => ({ value: addInfo }));
        return labelArray.concat(InfoArray);
    }
}
function renderProductInfoItems(items) {
    return items.map((item) => renderProductInfoItem(item, styles.productInfoItem));
}
function renderProductInfoItem(item, className) {
    return (React.createElement(VerticalContainer, { horizontalAlign: "flex-start", verticalAlign: "flex-start", key: `product-info-${item.name}`, className: className },
        React.createElement("div", { className: styles.productInfoName }, item.name),
        React.createElement("div", { className: styles.productInfoValue }, formatProductInfoValue(item.value))));
}
function formatProductInfoValue(values) {
    return values.map((v, i) => [
        React.createElement("span", { key: `value-${i}`, className: selectClassNames(styles, {
                productInfoValueIndent: v.indent || false,
            }) }, v.value),
        React.createElement("br", { key: `br-${i}` }),
    ]);
}

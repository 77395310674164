import { useAppDispatch, useAppSelector } from '../hooks.js';
import { useEffect } from 'react';
import { useGetArticlesByTagQuery } from '../services/api.js';
import { updateTagFilter, setArticlesByTagSearchResult, updateHasMore } from '../reducers/articlesByTagSlice.js';
import { ARTICLE_PAGE_SIZE } from '../../common/constants.js';
export const useArticlesByTagQuery = (urlParam) => {
    var _a, _b;
    const dispatch = useAppDispatch();
    const { articleSearchResultByTag, offset, articleTotalHitCount } = useAppSelector((state) => state.articlesByTag);
    const searchQueryResult = useGetArticlesByTagQuery({ offset, limit: ARTICLE_PAGE_SIZE + offset, tag: urlParam, searchPhrase: '' }, { skip: !urlParam });
    useEffect(() => {
        if (searchQueryResult.data) {
            dispatch(setArticlesByTagSearchResult(searchQueryResult.data));
            dispatch(updateTagFilter(urlParam));
            dispatch(updateHasMore());
        }
    }, [searchQueryResult.data]);
    return {
        data: articleSearchResultByTag || ((_a = searchQueryResult.data) === null || _a === void 0 ? void 0 : _a.articles) || [],
        total: (articleTotalHitCount !== undefined ? articleTotalHitCount : (_b = searchQueryResult.data) === null || _b === void 0 ? void 0 : _b.total) || 0,
        isError: searchQueryResult.isError,
        isLoading: searchQueryResult.isFetching || searchQueryResult.isLoading,
    };
};

import * as R from 'ramda';
import { LandingPageElementType, } from '../common/apiTypes.js';
import { baseUrl } from './config.js';
import { getBrandMeta, getRecipeMeta, getWebPageMeta, getLocalBusinessMeta, getProductMeta, getArticleMeta, getPartnerMeta, } from '@kesko/kespro-ui-common';
const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const logoUrl = `${baseUrl}/assets/images/kespro_metalogo.png`;
export const defaultDescriptions = {
    main: 'Tehdään yhdessä ulkona syömisestä elämyksellisempää, vastuullisempaa ja siten suositumpaa.',
    product: 'Kespron laajasta HoReCa-valikoimasta löydät liikeideaasi sopivat laadukkaat tuotteet',
    products: 'Kespron laajasta HoReCa-valikoimasta löydät liikeideaasi sopivat laadukkaat tuotteet ja raaka-aineet sekä meiltä että maailmalta. Tutustu laajaan valikoimaan!',
    recipe: 'Kespron reseptisivuilta löydät tuotekehityskeittiössämme testatut reseptit, ideat ja inspiraatiot. Sovella rohkeasti omaan liikeideaasi sopivaksi tai hyödynnä sellaisenaan!',
    company: 'Kespro on Kesko Oyj:n omistama tukku, jolla on Suomen laajin foodservice-alan tukkuvalikoima. Asiakkaitamme ovat mm. ravintolat, hotellit, kahvilat, liikenneasemat, julkishallinnon laitokset, K-ryhmän kaupat sekä jälleenmyyntiasiakkuudet.',
    magazines: 'Tarjoamme julkaisuissamme ruokamaailman uutisia, ajankohtaisia tuotteita ja reseptejä sekä kansainvälisiä trendejä. Tutustu!',
    suppliers: 'Ammattilaiset Kespron ostossa auttavat tavarantoimittajia kehittämään liiketoimintaansa HoReCa-markkinassa. Tehdään yhdessä ulkona syömisestä elämyksellisempää, vastuullisempaa ja siten suositumpaa.',
    contact: 'Tehdään yhdessä ulkona syömisestä elämyksellisempää, vastuullisempaa ja siten suositumpaa. Kuinka voimme olla avuksi?',
    wholesale: {
        fi: (name) => `Kespron tukku ${name} palvelee paikallisesti laajalla foodservice-alan valikoimalla. Tervetuloa!`,
        en: (name) => `Kespro cash and carry ${name} offers wide variety of foodservice products - locally. Welcome!`,
        sv: (name) => `Kespro grossistbutik ${name} erbjuder ett brett grossistutbud. Välkommen!`,
        ru: undefined,
    },
    restaurantWholesale: 'Keittiö päättää tahdin - Ensimmäistä kertaa Suomessa. Kespron ravintolatukun palvelut tekevät arjestasi entistä sujuvampaa. Ravintolatukusta saat kaiken ravintolaasi yhdellä toimituksella 6, 10 tai 24 h rytmissä – ja tietysti ympäristöä säästäen.',
};
export const defaultLocalizedDescriptions = {
    contact: {
        en: `${defaultDescriptions.main} Contact information.`,
        sv: `${defaultDescriptions.main} Kontakt information.`,
    },
    brief: {
        en: `${defaultDescriptions.main} Kespro in brief.`,
        sv: `${defaultDescriptions.main} Kort om Kespro.`,
        ru: `${defaultDescriptions.main} Kespro po russki.`,
    },
};
export const defaultPublisherData = {
    name: 'Kespro',
    url: 'https://www.kespro.com',
    logoUrl,
};
const productMetaBrand = (product) => getBrandMeta(product.brand ? product.brand : '');
const productImageUrl = (product) => (product.images && product.images.length > 0)
    ? `${product.images[0].url}?w=1000`
    : logoUrl;
const preprocessMediaUrl = (url) => url !== undefined ? url.replace(/^\/\//, 'https://') : logoUrl;
const productCanonicalUrl = (product) => `${baseUrl}/tuotteet/${product.slug || product.ean}`;
const productMetaCategory = (product) => {
    if (!product.categories) {
        return '';
    }
    const categoriesWithoutLevel4Category = product.categories.filter((category) => category.level < 4);
    return R.sortBy(R.prop('level'))(categoriesWithoutLevel4Category).map((category) => category.name).join('>');
};
const listOpeningHours = (contact) => [
    contact.openingHoursMon,
    contact.openingHoursTue,
    contact.openingHoursWed,
    contact.openingHoursThu,
    contact.openingHoursFri,
    contact.openingHoursSat,
    contact.openingHoursSun,
];
const parseOpeningHours = (hours, dayOfWeek) => {
    if (hours === 'suljettu') {
        return { dayOfWeek, opens: '00:00', closes: '00:00' };
    }
    const [opens, closes] = hours.split('-').map((v) => v.trim().replace('.', ':'));
    return { dayOfWeek, opens, closes };
};
const parseExceptionalOpeningHours = (hours, dayTimestamp) => {
    const date = new Date(dayTimestamp).toISOString().substring(0, 10);
    return Object.assign(Object.assign({}, parseOpeningHours(hours)), { validFrom: date, validThrough: date });
};
const openingHours = (hours, exceptionalOpeningHours) => hours
    .map((x, i) => parseOpeningHours(x, weekDays[i]))
    .concat(exceptionalOpeningHours.map((x) => parseExceptionalOpeningHours(x.time, Number(x.date))));
export const webPageMeta = ({ name, image, url, description, robots }) => getWebPageMeta({
    name,
    image: image || logoUrl,
    url,
    description,
    publisher: defaultPublisherData,
    robots,
});
export const articleMeta = (article) => {
    const robots = [`unavailable_after: ${article.expirationTime}`];
    if (article.excludeFromSitemap) {
        robots.push('noindex');
    }
    return getArticleMeta({
        name: article.title,
        image: preprocessMediaUrl(article.heroImageUrl),
        url: `${baseUrl}/ideat-ja-inspiraatiot/artikkelit/${article.slug}`,
        description: article.lead,
        authorName: article.author ? article.author.name : undefined,
        publisher: defaultPublisherData,
        robots,
    });
};
export const recipeMeta = (recipe) => {
    const robots = recipe.excludeFromSitemap ? ['noindex'] : undefined;
    return getRecipeMeta({
        name: recipe.name,
        image: preprocessMediaUrl(recipe.heroImageUrl),
        url: `${baseUrl}/ideat-ja-inspiraatiot/reseptit/${recipe.slug}`,
        description: recipe.lead || `${recipe.name} - ${defaultDescriptions.recipe}`,
        publisher: defaultPublisherData,
        ingredients: R.chain((i) => i.ingredients.map((ing) => (Object.assign(Object.assign({}, ing), { amount: ing.amount }))), recipe.ingredients),
        instructions: recipe.instructions,
        robots,
    });
};
export const partnerMeta = (partner) => {
    const robots = ['noindex'];
    return getPartnerMeta({
        name: partner.title,
        url: `${baseUrl}/kumppani/${partner.title}`,
        description: partner.title,
        robots,
    });
};
export const brandPageMeta = (data) => webPageMeta(Object.assign(Object.assign({}, data), { description: data.description || defaultDescriptions.main }));
export const productMeta = (product) => getProductMeta({
    name: product.name,
    description: `${product.name} - ${defaultDescriptions.product}`,
    brand: productMetaBrand(product),
    ean: product.ean,
    image: productImageUrl(product),
    url: productCanonicalUrl(product),
    category: productMetaCategory(product),
});
export const landingPageMeta = (page) => {
    const hero = R.find((el) => el.type === LandingPageElementType.Hero, page.content);
    const description = page.lead;
    const image = hero && hero.image ? `https:${hero.image}` : undefined;
    const robotsMeta = page.excludeFromSitemap ? ['noindex'] : undefined;
    return webPageMeta({
        name: page.title,
        description,
        image,
        url: `${baseUrl}/${page.slug}`,
        robots: robotsMeta,
    });
};
export const localBusinessMeta = (contact, language) => {
    const getWholesaleDefaultDescription = defaultDescriptions.wholesale[language];
    return getLocalBusinessMeta({
        name: contact.name,
        description: getWholesaleDefaultDescription && getWholesaleDefaultDescription(contact.name),
        email: contact.email || '',
        telephone: contact.phone,
        faxNumber: contact.fax || '',
        address: {
            streetAddress: contact.address,
            locality: contact.locality,
        },
        image: preprocessMediaUrl(contact.image),
        openingHoursSpecification: openingHours(listOpeningHours(contact), contact.exceptionalOpeningHours),
    });
};
export const locationMeta = (contact) => {
    let description = '';
    for (const element of contact.description.content) {
        if (element.nodeType === 'paragraph' && element.content && element.content[0]) {
            const content = element.content[0];
            if ('value' in content && content.value) {
                description = content.value;
            }
        }
    }
    return getLocalBusinessMeta({
        name: contact.name,
        description,
        email: '',
        telephone: '',
        faxNumber: '',
        address: {
            streetAddress: contact.address,
            locality: contact.locality,
        },
        image: preprocessMediaUrl(contact.image),
        openingHoursSpecification: [],
    });
};

import React, { useEffect } from 'react';
import { findCategoryById } from '@kesko/kespro-ui-common';
import { slugifyBrand } from '@kesko/kespro-product-service-api';
import { Link } from 'react-router-dom';
import { NotFoundPage } from '../NotFoundPage/index.js';
import { baseUrl } from '../../config.js';
import { defaultDescriptions, productMeta } from '../../meta.js';
import { productPageNewClientLinkClickedEvent } from '../../reducers/analyticsSlice.js';
import { useGetCategoryHierarchyQuery, useGetProductQuery } from '../../services/api.js';
import { useAppSelector } from '../../hooks.js';
import { ProductInfoContainer } from './ProductInfoContainer/index.js';
import { ProductRecommendationsContainer } from './ProductRecommendationsContainer/index.js';
import { TextWithIcon } from '../../components/TextWithIcon.js';
import { getProductImageSrcSet } from '../../util/image.js';
import { Icon } from '../../Icon.js';
import { VerticalContainer, HorizontalContainer } from '../../components/FlexboxContainer.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { PreloadedImage } from '../../components/PreloadedImage.js';
import styles from './style.scss';
const kesproNetBaseUrl = process.env.KESPRO_NET_BASE_URL;
const ProductPageBody = ({ product, categoryHierarchy, substitutes, relatedProducts }) => {
    var _a;
    const [substitutesCollapsed, setSubstitutesCollapsed] = React.useState(false);
    const [relatedProductsCollapsed, setRelatedProductsCollapsed] = React.useState(false);
    const [infoContainerCollapsed, setInfoContainerCollapsed] = React.useState(false);
    const toggleSubstitutesCollapse = () => {
        setSubstitutesCollapsed(!substitutesCollapsed);
    };
    const toggleRelatedProductsCollapsed = () => {
        setRelatedProductsCollapsed(!relatedProductsCollapsed);
    };
    const toggleInfoContainerCollapse = () => {
        setInfoContainerCollapsed(!infoContainerCollapsed);
    };
    useEffect(() => {
        setInfoContainerCollapsed(false);
        setSubstitutesCollapsed(false);
    }, [product]);
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const imageSrcSetRes = ((_a = product.images) === null || _a === void 0 ? void 0 : _a.length)
        ? getProductImageSrcSet(product.images, IMAGE_WIDTH, IMAGE_WIDTH)
        : undefined;
    const imageSrcSet = imageSrcSetRes === null || imageSrcSetRes === void 0 ? void 0 : imageSrcSetRes.srcSet;
    const parentDataSubstitutes = {
        item_list_id: product.ean,
        item_list_name: product.name,
        item_list_title: 'substitute',
        model_date: substitutes.model_date,
    };
    const parentDataRelatedProducts = {
        item_list_id: product.ean,
        item_list_name: product.name,
        item_list_title: 'related',
        model_date: relatedProducts.model_date,
    };
    return (React.createElement(React.Fragment, null,
        renderDesktopBody(product, imageSrcSet, categoryHierarchy, productPageNewClientLinkClickedEvent, isLoggedIn),
        renderMobileBody(product, imageSrcSet, categoryHierarchy, productPageNewClientLinkClickedEvent, isLoggedIn),
        React.createElement(ProductInfoContainer, { product: product, collapsed: infoContainerCollapsed, toggleCollapse: toggleInfoContainerCollapse }),
        React.createElement(ProductRecommendationsContainer, { recommendations: substitutes, parentData: parentDataSubstitutes, collapsed: substitutesCollapsed, toggleCollapse: toggleSubstitutesCollapse }),
        React.createElement(ProductRecommendationsContainer, { recommendations: relatedProducts, parentData: parentDataRelatedProducts, collapsed: relatedProductsCollapsed, toggleCollapse: toggleRelatedProductsCollapsed })));
};
const FALLBACK_PRODUCT_IMAGEURL = `${baseUrl}/images/no-image.jpeg`;
const IMAGE_WIDTH = 400;
function renderDesktopBody(product, imageSrcSet, categoryHierarchy, newClientLinkClicked, isLoggedIn) {
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", className: styles.desktopContainer },
        renderCategoryBreadCrumbs(product.categories, categoryHierarchy),
        React.createElement(HorizontalContainer, { className: styles.columnLayout },
            imageSrcSet ? (React.createElement(PreloadedImage, { containerClassName: styles.imageContainer, imageUrl: product.images[0].url, imageSrcset: imageSrcSet, imageWidth: IMAGE_WIDTH, imageHeight: IMAGE_WIDTH, alt: product.name, imageClassName: styles.image, fallbackImageUrl: FALLBACK_PRODUCT_IMAGEURL })) : (React.createElement("div", { className: styles.imageContainer })),
            React.createElement(VerticalContainer, { className: styles.productTextContainer },
                renderBrandLink(product),
                React.createElement("h1", { className: styles.productName }, product.name),
                renderEanAndSap(product),
                renderClientLinks(product, newClientLinkClicked, isLoggedIn)))));
}
function renderMobileBody(product, imageSrcSet, categoryHierarchy, newClientLinkClicked, isLoggedIn) {
    return (React.createElement(VerticalContainer, { className: styles.mobileContainer },
        renderCategoryBreadCrumbs(product.categories, categoryHierarchy),
        React.createElement("h1", { className: styles.productName }, product.name),
        renderBrandLink(product),
        imageSrcSet ? (React.createElement(PreloadedImage, { containerClassName: styles.imageContainer, imageUrl: product.images[0].url, imageSrcset: imageSrcSet, imageHeight: IMAGE_WIDTH, imageWidth: IMAGE_WIDTH, alt: product.name, imageClassName: styles.image, fallbackImageUrl: FALLBACK_PRODUCT_IMAGEURL })) : undefined,
        renderEanAndSap(product),
        renderClientLinks(product, newClientLinkClicked, isLoggedIn)));
}
export function getCategoryLink(category) {
    return `/tuotteet?kategoria=${category.category.id}`;
}
export function getLastCategory(categories, hierarchy) {
    for (let i = categories.length - 1; i >= 0; --i) {
        const cat = categories[i];
        const category = findCategoryById(hierarchy, cat.id);
        if (!category) {
            continue;
        }
        return category;
    }
    return undefined;
}
function renderCategoryBreadCrumbs(categories, hierarchy) {
    if (!categories) {
        return undefined;
    }
    const links = [];
    let category = getLastCategory(categories, hierarchy);
    if (!category) {
        return links;
    }
    while (category && category.category.id !== 'root') {
        const categoryLink = getCategoryLink(category);
        links.push(React.createElement("li", { key: category.category.id },
            React.createElement(Link, { to: categoryLink }, category.category.name)));
        category = category.parent;
    }
    return (React.createElement("nav", { "aria-label": "Breadcrumb", className: styles.breadcrumb },
        React.createElement("ul", null, ...links.reverse())));
}
function renderBrandLink(product) {
    if (product.brand) {
        return (React.createElement("div", null,
            React.createElement(Link, { className: styles.brandLink, to: `/tuotteet/tuotemerkit/${encodeURIComponent(slugifyBrand(product.brand))}` }, product.brand)));
    }
    else {
        return undefined;
    }
}
function trimLeadingZeros(value) {
    return value.replace(/^0*/, '');
}
function renderEanAndSap(product) {
    return (React.createElement(VerticalContainer, { className: styles.eanSapContainer },
        React.createElement("p", null,
            "EAN: ",
            product.ean),
        React.createElement("p", null,
            "SAP: ",
            trimLeadingZeros(product.id))));
}
function renderClientLinks(product, newClientLinkClicked, isLoggedIn) {
    const kruokaUrl = product.baseEan
        ? `https://www.k-ruoka.fi/kauppa/tuotehaku?tuote=${product.baseEan}`
        : 'https://www.k-ruoka.fi';
    return (
    // This useless looking div is an IE fix. Without it this will receive a height of 0px.
    React.createElement("div", null,
        React.createElement(HorizontalContainer, { className: styles.clientLinkContainer },
            React.createElement("a", { href: `${kesproNetBaseUrl}/haku/${product.ean}`, className: styles.clientLink, target: "_blank", rel: "noopener noreferrer" },
                React.createElement(HorizontalContainer, { horizontalAlign: "space-between", verticalAlign: "center" },
                    React.createElement(VerticalContainer, null,
                        React.createElement("p", { className: !isLoggedIn ? styles.clientLinkSubtitle : styles.hidden }, "Nykyinen asiakas:"),
                        React.createElement("p", null, "Tarkista hinnat ja saatavuus Kespronetist\u00E4")),
                    React.createElement(Icon, { type: "chevron-right", size: 50 }))),
            !isLoggedIn ? (React.createElement(Link, { to: "/tukku/asiakkaaksi", className: styles.clientLink, onClick: () => {
                    newClientLinkClicked();
                } },
                React.createElement(HorizontalContainer, { horizontalAlign: "space-between", verticalAlign: "center" },
                    React.createElement(VerticalContainer, null,
                        React.createElement("p", { className: styles.clientLinkSubtitle }, "Uusi asiakas:"),
                        React.createElement("p", { className: styles.clientLinkText }, "Hae asiakkuutta")),
                    React.createElement(Icon, { type: "chevron-right", size: 50 })))) : (React.createElement(React.Fragment, null))),
        !isLoggedIn && (React.createElement(TextWithIcon, { className: styles.link, icon: "chevron-right", href: kruokaUrl, reversed: true }, "Oletko kuluttaja? Siirry K-Ruoka.fi -verkkokauppaan"))));
}
const ProductPageHelmet = (props) => {
    var _a, _b;
    const pageMeta = props.product ? productMeta(props.product) : undefined;
    return (React.createElement(KesproComHelmet, { title: (_a = props.product) === null || _a === void 0 ? void 0 : _a.name, canonicalPath: `/tuotteet/${(_b = props.product) === null || _b === void 0 ? void 0 : _b.slug}`, description: pageMeta ? pageMeta.description : defaultDescriptions.products, pageMeta: pageMeta }));
};
const ProductPage = ({ product, categoryHierarchy, substitutes, relatedProducts }) => (React.createElement("div", null,
    React.createElement(ProductPageHelmet, { product: product }),
    React.createElement(ProductPageBody, { product: product, categoryHierarchy: categoryHierarchy, substitutes: substitutes, relatedProducts: relatedProducts })));
const ProductPageWrapper = (props) => {
    const useProductQueryResult = useGetProductQuery(props.match.params.productSlug);
    const useCategoryQueryResult = useGetCategoryHierarchyQuery();
    const hierarchy = useAppSelector((state) => state.category.hierarchy);
    if (useProductQueryResult.data && hierarchy) {
        return (React.createElement(ProductPage, { categoryHierarchy: hierarchy, product: useProductQueryResult.data.product, substitutes: useProductQueryResult.data.substitutes, relatedProducts: useProductQueryResult.data.relatedProducts }));
    }
    if (useProductQueryResult.isError || useCategoryQueryResult.isError) {
        return React.createElement(NotFoundPage, { language: "fi" });
    }
    return React.createElement(LoadingIndicator, { className: styles.loading });
};
export { ProductPageWrapper as ProductPage };

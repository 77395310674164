import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { FlocklerEmbed } from '../../lib/ReactFlocklerEmbed/index.js';
import { HOME_PAGE_UPPER_CARDS, HOME_PAGE_LOWER_CARDS } from '../../../common/constants.js';
import filterStyles from '../../components/FilterBar.scss';
import { combineClassNames } from '../../util/css/classNames.js';
import { useGetHomePageContentQuery, useGetIdeaInspirationCardsQuery } from '../../services/api.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { incrementOffset, setIdeaInspirationSearchResult, setIdeaInspirationSearchState } from '../../reducers/ideaInspirationSlice.js';
import { Icon } from '../../Icon.js';
import { HorizontalContainer } from '../../components/FlexboxContainer.js';
import { applyCardFilters, IdeaInspirationCardList } from '../../components/IdeaInspirationCardList.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { NotFoundComponent } from '../../components/NotFoundComponent.js';
import { ModularContent } from '../../components/ModularContent.js';
import { EmbeddedContentConsentRequired } from '../../components/EmbeddedContentConsentRequired.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import styles from './style.scss';
const HomepageSection = ({ children, className, style, refAction }) => (React.createElement("div", { className: combineClassNames(styles.homepageSectionContent, className || ''), style: style, ref: refAction }, children));
const rangeOfCards = (from, to, list) => R.slice(from, to === Infinity ? list.length : to, list);
const makeLimitedLowerCardList = (boundary, cards) => {
    const offset = HOME_PAGE_UPPER_CARDS + HOME_PAGE_LOWER_CARDS;
    return rangeOfCards(boundary, offset, cards);
};
const splitCards = (limitCardsVisible, cards) => {
    const upperListCards = rangeOfCards(0, HOME_PAGE_UPPER_CARDS, cards);
    const boundary = HOME_PAGE_UPPER_CARDS;
    const lowerListCards = limitCardsVisible
        ? makeLimitedLowerCardList(boundary, cards)
        : rangeOfCards(boundary, Infinity, cards);
    return [upperListCards, lowerListCards];
};
const FilterLinks = ({ current, setHomePageFilter }) => {
    const makeClickLink = (text, filter) => {
        const isCurrent = filter === current;
        const classes = combineClassNames(filterStyles.quickFilterBase, filterStyles.quickFilter, isCurrent ? filterStyles.active : '');
        return (React.createElement("a", { className: classes, href: "", onClick: (e) => {
                e.preventDefault();
                setHomePageFilter(filter);
            } }, text));
    };
    return (React.createElement("div", { className: combineClassNames(filterStyles.filterBar, styles.homePageFilterBar) },
        React.createElement("nav", { key: "mainNav", className: filterStyles.filters },
            makeClickLink('Kaikki', undefined),
            makeClickLink('Reseptit', 'recipe'),
            makeClickLink('Artikkelit', 'article'))));
};
const InstagramFeed = () => (React.createElement(EmbeddedContentConsentRequired, { alternativeLink: "https://www.instagram.com/kesprosuomi" },
    React.createElement("div", { className: styles.instagramFeed },
        React.createElement(HorizontalContainer, { className: styles.instagramHeader, verticalAlign: "center" },
            React.createElement(Icon, { type: "instagram", size: "40" }),
            React.createElement("h3", null, "Kespro Instagramissa")),
        React.createElement(FlocklerEmbed, { siteUuid: "1667bdd27180b69985b6a34a22bd0887", embedUuid: "17c6f2288d406b083d56b6362b8bc921" }))));
function HomePageComponent({ cards, cardsLoadingHasError, isLoadingMoreCards, hasMoreCards, content, limitCardsVisible }) {
    const dispatch = useAppDispatch();
    const [cardFilter, setCardFilter] = useState();
    const filteredCards = applyCardFilters(cardFilter, cards);
    const [upperListCards, lowerListCards] = splitCards(limitCardsVisible, filteredCards);
    const ideaInsprirationCardsDataAttributs = {
        indexNumber: -1,
        promotionParentType: 'IdeaInspirationCardList',
        promotionLocation: 'HomepageIdeaInspirationCardList',
    };
    const handleLoadMoreCards = () => {
        dispatch(incrementOffset());
    };
    const metaDescription = 'Kespro on Kesko Oyj:n omistama tukku, jolla on Suomen laajin foodservice-alan tukkuvalikoima. Asiakkaitamme ovat mm. ravintolat, hotellit, kahvilat, liikenneasemat, julkishallinnon laitokset, K-ryhmän kaupat sekä jälleenmyyntiasiakkuudet. Suhtaudumme intohimoisesti foodservice-alaan. Tavoitteenamme on - ei enempää eikä vähempää kuin - tehdä yhdessä asiakkaidemme kanssa ulkona syömisestä elämyksellisempää, vastuullisempaa ja entistä suositumpaa.';
    return (React.createElement("div", { className: styles.homepageContent },
        React.createElement(KesproComHelmet, { title: 'Kespro.com', canonicalPath: '/', description: metaDescription }),
        React.createElement("section", { key: "body", className: styles.bodyContainer },
            React.createElement(ModularContent, { content: content.modularContentTop, region: "top" }),
            React.createElement(IdeaInspirationCardList, { cards: upperListCards, hasMoreCards: false, renderMode: 'bigcards', analyticsOptions: ideaInsprirationCardsDataAttributs }),
            React.createElement(ModularContent, { content: content.modularContentMiddle, region: "middle" }),
            React.createElement(FilterLinks, { current: cardFilter, setHomePageFilter: setCardFilter }),
            React.createElement(IdeaInspirationCardList, { cards: lowerListCards, hasMoreCards: hasMoreCards, isError: cardsLoadingHasError, isLoadingMore: isLoadingMoreCards, onLoadMore: handleLoadMoreCards, renderMode: 'smallcards', analyticsOptions: ideaInsprirationCardsDataAttributs }),
            React.createElement(HomepageSection, null,
                React.createElement(InstagramFeed, null))),
        React.createElement(ModularContent, { content: content.modularContentBottom, region: "bottom" })));
}
const HomePageWrapper = (props) => {
    const dispatch = useAppDispatch();
    const cacheQueryParams = parseCacheQueryParams(props.location);
    const { searchPhrase, searchResult, hasMore, homePageLimitVisibleCardCount, query } = useAppSelector((state) => state.ideaInspiration);
    const homePageContentQueryResult = useGetHomePageContentQuery(cacheQueryParams);
    const ideaInspirationQueryResult = useGetIdeaInspirationCardsQuery(Object.assign(Object.assign({}, cacheQueryParams), query));
    useEffect(() => {
        if (searchPhrase !== '') {
            dispatch(setIdeaInspirationSearchState({ searchPhrase: '' }));
        }
    }, [searchPhrase]);
    useEffect(() => {
        if (ideaInspirationQueryResult.data) {
            dispatch(setIdeaInspirationSearchResult(ideaInspirationQueryResult.data));
        }
    }, [ideaInspirationQueryResult.data]);
    if (ideaInspirationQueryResult.data && homePageContentQueryResult.data) {
        return (React.createElement(HomePageComponent, { cards: searchResult || ideaInspirationQueryResult.data, cardsLoadingHasError: ideaInspirationQueryResult.isError, content: homePageContentQueryResult.data, hasMoreCards: hasMore, isLoadingMoreCards: ideaInspirationQueryResult.data && ideaInspirationQueryResult.isFetching, limitCardsVisible: homePageLimitVisibleCardCount }));
    }
    if (homePageContentQueryResult.isError) {
        return React.createElement(NotFoundComponent, null);
    }
    return React.createElement(LoadingIndicator, { className: styles.loading });
};
export { HomePageWrapper as HomePage };

import * as R from 'ramda';
import { getNearestWholesales } from './wholesaleProximity.js';
import { isWholesale, isCustomerService, isSalesPerson, isLocation, } from '../../common/apiTypes.js';
export const salesAreaMatches = (word, contact) => {
    if (contact.contentType === 'salesPerson') {
        return contact.salesAreas.some((area) => includesString(area.name, word));
    }
    return false;
};
export const filterBySalesType = (contact, filters) => {
    const hasSalesTypeFilters = Object.values(filters).includes(true);
    return Boolean(!hasSalesTypeFilters
        || (filters.noutotukut && ((isWholesale(contact))))
        || (isSalesPerson(contact) && filters[contact.salesType.toLowerCase()])
        || (isLocation(contact) && getLocationTypes(contact, filters)));
};
const getLocationTypes = (location, filters) => location.salesType && location.salesType.some((salesType) => salesType === 'Kaikki' || filters[salesType.toLowerCase()]);
const isSalesPersonInSalesAreaLocation = (salesPerson, filters) => salesPerson.salesAreaLocations.some((name) => name === 'Kaikki' || filters[name.toLowerCase()]);
export const filterBySalesAreaLocation = (contact, filters) => {
    const hasFilters = Object.values(filters).includes(true);
    return Boolean(!hasFilters
        || ((isWholesale(contact) || isLocation(contact)) && filters[contact.salesAreaLocation.toLowerCase()])
        || (isSalesPerson(contact) && isSalesPersonInSalesAreaLocation(contact, filters)));
};
export const searchContacts = (contacts, searchPhrase, filters, contactType) => {
    const filteredContacts = filterContacts(contacts, filters || []);
    let searchedContacts = searchContactsWithSearchPhrase(filteredContacts, searchPhrase);
    if (contactType && contactType === 'kaikki') {
        // wholesale contacts are sorted separately because we are also showing 'location' type of contacts among them
        const sortedWholesaleContacts = [];
        searchedContacts.forEach((contact) => {
            if (contact.contentType === 'wholesale' || contact.contentType === 'location') {
                sortedWholesaleContacts.push(contact);
            }
        });
        searchedContacts = searchedContacts.filter((contact) => !sortedWholesaleContacts.includes(contact));
        sortedWholesaleContacts.sort(R.ascend((c) => c.locality));
        searchedContacts.sort(R.ascend((c) => c.name));
        return searchedContacts.concat(sortedWholesaleContacts);
    }
    return searchedContacts.sort(R.ascend((c) => c.name));
};
const searchContactsWithSearchPhrase = (contacts, searchPhrase) => {
    if (!searchPhrase) {
        return contacts;
    }
    const lowercaseSearchPhrase = searchPhrase.toLowerCase();
    const words = lowercaseSearchPhrase
        // Strips multiple spaces off e.g. 'FirstName   LastName'
        .replace(/ +/g, ' ')
        .split(' ');
    const exactWholesale = contacts.find((contact) => (isWholesale(contact) || isLocation(contact)) && includesString(contact.locality, lowercaseSearchPhrase));
    const nearestWholesales = exactWholesale
        ? []
        : getNearestWholesales(searchPhrase).map((wholesale) => wholesale.toLowerCase());
    return contacts.filter((contact) => {
        const matches = words.map((word) => contactIncludesWord(contact, word));
        return !matches.includes(false) || isNearbyWholesale(contact, nearestWholesales);
    });
};
const filterContacts = (contacts, filters) => contacts.filter((contact) => isSearchable(contact) && (filters.reduce((result, extraFilter) => result && extraFilter(contact), true)));
const contactIncludesWord = (contact, word) => {
    const propertyNames = ['name', 'businessTitle', 'locality', 'salesType'];
    for (const propertyName of propertyNames) {
        const property = contact[propertyName];
        if (includesString(property, word)) {
            return true;
        }
    }
    return salesAreaMatches(word, contact);
};
const includesString = (stringOrArray, text) => {
    if (typeof stringOrArray === 'string') {
        return stringOrArray.toLowerCase().includes(text.toLowerCase());
    }
    else if (Array.isArray(stringOrArray)) {
        return stringOrArray.includes(text.toLowerCase());
    }
    return false;
};
// By default, only "wholesale", "customerService", "location" and "salesPerson" is searchable.
const isSearchable = (contact) => isWholesale(contact)
    || isSalesPerson(contact)
    || isLocation(contact)
    || (isCustomerService(contact) && !contact.wholesale);
const isNearbyWholesale = (contact, nearestWholesales) => nearestWholesales.length
    && (isWholesale(contact) || isLocation(contact))
    && includesString(nearestWholesales, contact.locality.toLowerCase());
export const transformContactsInCombinedSearchResult = (searchResult, query) => {
    const { searchPhrase } = query;
    const contacts = searchContacts(searchResult.hits.contacts, searchPhrase || '');
    // Note this only works properly when used in RTK query as it uses immer plugin.
    searchResult.hits.contacts = contacts;
    searchResult.totalHitCounts.contacts = contacts.length;
    searchResult.totalHitCounts.total = (searchResult.totalHitCounts.articles
        + searchResult.totalHitCounts.recipes
        + searchResult.totalHitCounts.products
        + searchResult.totalHitCounts.contentPages
        + contacts.length);
    return searchResult;
};

import React, { useState } from 'react';
import * as R from 'ramda';
import { NotFoundPage } from '../NotFoundPage/index.js';
import { isCustomerService, isWholesalePerson, LandingPageElementType, } from '../../../common/apiTypes.js';
import { localBusinessMeta } from '../../meta.js';
import { useGetContactsQuery } from '../../services/api.js';
import { useMemo } from 'react';
import { renderRtkQueryResult } from '../../util/asyncData.js';
import { combineClassNames } from '../../util/css/classNames.js';
import { formatTodayOpened } from '../../util/openingHours.js';
import { useAppSelector } from '../../hooks.js';
import { i18nTexts as i18nTextsRoot } from './ContactInfoPageI18n.js';
import { becomeClientPageUrls } from '../../staticData.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { renderAlternateLinks } from '../../components/renderAlternateLinks.js';
import { wholesaleListPageUrls } from '../../../common/urlPaths.js';
import { Button } from '../../components/Button.js';
import { Icon } from '../../Icon.js';
import { ContactInfoDescription } from '../../components/ContactInfoDescription.js';
import { ContactInfoHours } from '../../components/ContactInfoHours.js';
import { ContactInfoPageHeader } from '../../components/ContactInfoPageHeader.js';
import { ContactInfoPeople } from '../../components/ContactInfoPeople.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import style from './style.scss';
import { ContactInfoEvents } from '../../components/ContactInfoEvents.js';
const filterContactPersons = (wholesale, contacts) => {
    const matchLocality = (c) => (c.wholesale && c.wholesale.locality === wholesale.locality) || false;
    return contacts.filter(R.anyPass([
        (c) => isCustomerService(c) && matchLocality(c),
        (c) => isWholesalePerson(c) && matchLocality(c),
    ]));
};
const ContactInfoPageHelmet = ({ contactId, wholesale }) => {
    const { language, localizedSlugs } = useAppSelector((state) => state.i18n);
    const canonicalPath = `${wholesaleListPageUrls[language]}/${contactId}`;
    const pageMeta = localBusinessMeta(wholesale, language);
    return (React.createElement(KesproComHelmet, { title: wholesale.name, canonicalPath: canonicalPath, description: pageMeta.description, pageMeta: pageMeta }, renderAlternateLinks(language, localizedSlugs)));
};
const WholesaleContactInfoPage = ({ contacts, contactId, language, i18nTexts }) => {
    const wholesale = useMemo(() => contacts.find((contact) => contact.contentType === 'wholesale' && contact.slug === contactId), [contacts, contactId]);
    const showIconIfText = (label, icon, link) => {
        if (label) {
            const children = [
                React.createElement("div", { key: `${label}-icon`, className: style.iconAndLabel__icon }, icon !== 'fax' ? React.createElement(Icon, { key: `${icon}-icon`, type: icon, size: 28 }) : React.createElement("span", null, "FAX")),
                React.createElement("span", { key: `${icon}-label` }, label),
            ];
            if (link) {
                return React.createElement("a", { href: link, className: style.iconAndLabel }, children.map((child) => child));
            }
            else {
                return React.createElement("div", { className: style.iconAndLabel }, children.map((child) => child));
            }
        }
        return undefined;
    };
    const HeaderBar = (props) => {
        var _a;
        const [showEmail, setShowEmail] = useState(false);
        const openHoursToday = formatTodayOpened(props.contact, language);
        const email = (_a = props.contact.email) !== null && _a !== void 0 ? _a : '';
        const decodedEmail = useMemo(() => {
            try {
                return atob(email);
            }
            catch (e) {
                // eslint-disable-next-line no-console
                console.error('Failed to decode email: ', e);
                return email;
            }
        }, [email]);
        return (React.createElement("div", { className: combineClassNames(style.headerBarContainer) },
            React.createElement("div", { className: style.header__info },
                React.createElement("h1", null, props.contact.name),
                React.createElement("p", { className: style.todayOpened }, openHoursToday.hours
                    ? `${openHoursToday.label} ${openHoursToday.hours}`
                    : openHoursToday.label)),
            React.createElement("div", { className: style.headerBar },
                showIconIfText(props.contact.address, 'icon-location', props.contact.addressLink),
                !showEmail && decodedEmail.length > 0 &&
                    React.createElement("div", { className: style.iconAndLabel },
                        React.createElement("div", { key: `icon`, className: style.iconAndLabel__icon },
                            React.createElement(Icon, { key: `icon-mail`, type: 'icon-mail', size: 28 })),
                        React.createElement("button", { className: style.emailButton, "data-pw": "emailButton", onClick: () => setShowEmail(!showEmail) }, "N\u00E4yt\u00E4 s\u00E4hk\u00F6postiosoite")),
                showEmail && showIconIfText(decodedEmail, 'icon-mail', `mailto:${decodedEmail}`),
                showIconIfText(props.contact.phone, 'icon-phone', `tel:${props.contact.phone}`),
                showIconIfText(props.contact.fax, 'fax')),
            React.createElement("p", { className: style.phoneCallInfo }, i18nTexts.phoneCallPricing)));
    };
    return (wholesale ?
        React.createElement("article", { className: style.contactInfoPage },
            React.createElement(ContactInfoPageHelmet, { wholesale: wholesale, contactId: contactId }),
            React.createElement(ContactInfoPageHeader, { isLocation: false, heroImageUrl: wholesale.liftUpImage }),
            React.createElement("div", { className: style.becomeACustomerLinkContainer },
                React.createElement(Button, { classNameButtonContent: style.contactButton, isExternalLink: false, link: becomeClientPageUrls[language], text: i18nTexts.becomeCustomer, size: "small" /* ButtonSize.Small */, type: LandingPageElementType.Button, buttonType: "primary" /* ButtonVariant.Primary */ })),
            React.createElement(HeaderBar, { contact: wholesale }),
            React.createElement("div", { className: style.contactInfoPage__contentContainer },
                React.createElement(ContactInfoDescription, { description: wholesale.description, descriptionRichText: wholesale.descriptionRichText }),
                React.createElement("div", { className: style.becomeClientContainer },
                    React.createElement(Button, { isExternalLink: false, link: '/tukku/tukkukortti', text: i18nTexts.becomeCustomer, size: "small" /* ButtonSize.Small */, type: LandingPageElementType.Button, buttonType: "secondary" /* ButtonVariant.Secondary */ })),
                React.createElement(ContactInfoHours, { title: i18nTexts.openingHoursTitle, upcomingExceptionsTitle: i18nTexts.upcomingExceptionsTitle, styling: "detail", data: wholesale }),
                React.createElement(ContactInfoPeople, { title: i18nTexts.contactPeopleListHeader, disclaimer: i18nTexts.contactPeopleListDisclaimer, contacts: filterContactPersons(wholesale, contacts) }),
                React.createElement("div", { className: style.contactLinkContainer },
                    React.createElement(Button, { classNameButtonContent: style.contactButton, isExternalLink: true, link: `mailto:${wholesale.email}`, text: i18nTexts.contactUs, size: "small" /* ButtonSize.Small */, type: LandingPageElementType.Button, buttonType: "primary" /* ButtonVariant.Primary */ }))),
            React.createElement(ContactInfoEvents, { events: wholesale.events, language: language }))
        :
            React.createElement(NotFoundPage, { language: language }));
};
const ContactInfoPageWrapper = (props) => {
    const contactId = props.match.params.contactId;
    const { locale, language } = useAppSelector((state) => state.i18n);
    const contactsQueryResult = useGetContactsQuery(Object.assign({ locale }, parseCacheQueryParams(props.location)));
    const i18nTexts = i18nTextsRoot[language];
    return renderRtkQueryResult(WholesaleContactInfoPage, { contactId, language, i18nTexts }, contactsQueryResult, (contactInfoPageProps, contacts) => (Object.assign(Object.assign({}, contactInfoPageProps), { contacts })), { fetch404PageFromContentful: true });
};
export { ContactInfoPageWrapper as WholesaleContactInfoPage };

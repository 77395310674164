import React from 'react';
import { Icon } from '../Icon.js';
import { combineClassNames } from '../util/css/classNames.js';
import { HorizontalContainer } from './FlexboxContainer.js';
import styles from './Accordion.scss';
export const Accordion = ({ collapsed, toggleCollapse, title, children, id, 'aria-label': ariaLabel, }) => (React.createElement(React.Fragment, null,
    React.createElement(HorizontalContainer, { className: combineClassNames(styles.accordionContainer, !collapsed && styles.open) },
        React.createElement("h2", { className: styles.heading },
            React.createElement("button", { className: styles.chevronButton, onClick: toggleCollapse, "aria-label": ariaLabel || `Toggle ${title}`, "aria-expanded": !!collapsed, "aria-controls": id },
                React.createElement("span", null, title),
                React.createElement(Icon, { type: "chevron-down", size: 48 })))),
    React.createElement("div", { className: combineClassNames(styles.infoBox, collapsed ? styles.hidden : ''), id: id }, children)));

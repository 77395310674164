import React from 'react';
import { Feedbackly } from './Feedbackly.js';
import { AttributeBoxes } from './Attribute.js';
import { ArticleTags } from './ArticleTags.js';
import { VerticalContainer, HorizontalContainer } from './FlexboxContainer.js';
import { Heading } from './Heading.js';
import { HeroHeader } from './HeroHeader.js';
import { KesproInfoBoxes } from './KesproInfoBoxes.js';
import { Lead } from './Lead.js';
import { MarkdownPresenter } from './MarkdownPresenter.js';
import { PreviewNotification } from './PreviewNotification.js';
import { RecommendationList } from './RecommendationList.js';
import { SharingButtons } from './SharingButtons.js';
import { combineClassNames } from '../util/css/classNames.js';
import styles from './Article.scss';
export const Article = ({ article, pageUrl }) => {
    var _a, _b;
    const { recommendations, title, lead, heroImageUrl, body, preview } = article;
    const [previewVisible, setPreviewVisible] = React.useState(preview);
    const attributeBoxData = [
        {
            header: 'Julkaistu',
            text: article.publishTime,
            time: true,
        },
        {
            header: 'Päivitetty',
            text: article.updatedAt,
            time: true,
        },
        {
            header: 'Teksti',
            text: (_a = article.author) === null || _a === void 0 ? void 0 : _a.name,
        },
        {
            header: 'Kuvat',
            text: (_b = article.photographer) === null || _b === void 0 ? void 0 : _b.name,
        },
        {
            header: 'Kaupallinen yhteistyö',
            text: article.tradeCooperation,
            tradeCooperation: !!article.tradeCooperation,
        },
    ];
    return (React.createElement(VerticalContainer, { horizontalAlign: "stretch", wrap: false },
        previewVisible
            ? React.createElement(PreviewNotification, { hidePreview: () => { setPreviewVisible(false); } })
            : undefined,
        React.createElement(HeroHeader, { img: heroImageUrl, childrenContainerStyle: combineClassNames(styles.header, styles.noGradient), headerContainerClassname: combineClassNames(styles.image, styles.noGradient) }),
        React.createElement("section", { className: styles.headerLeadContainer },
            React.createElement(Heading, { className: styles.heading }, article.title),
            lead && React.createElement(Lead, null, lead)),
        React.createElement(AttributeBoxes, { data: attributeBoxData, slug: article.tradeCooperationSlug }),
        React.createElement("section", { className: styles.tagContainer },
            React.createElement(ArticleTags, { tags: article.tags })),
        React.createElement(MarkdownPresenter, { key: "markdown", markdownContent: body }),
        React.createElement(VerticalContainer, { horizontalAlign: "center" },
            React.createElement(HorizontalContainer, { className: styles.articleFooter },
                React.createElement(SharingButtons, { pageUrl: pageUrl, text: title })),
            React.createElement(Feedbackly, null)),
        React.createElement(KesproInfoBoxes, null),
        React.createElement(RecommendationList, { recommendations: recommendations })));
};

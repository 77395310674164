import React from 'react';
import { NavLinkWithScroll } from '../../util/scroll.js';
import { Icon } from '../../Icon.js';
import { useAppSelector } from '../../hooks.js';
import { useLocation } from 'react-router';
import { SSO_POSTLOGIN_URL } from '../../../common/queryStrings.js';
import { HorizontalContainer } from '../FlexboxContainer.js';
import { combineClassNames } from '../../util/css/classNames.js';
import styles from './LogIn.scss';
function getLink(className, name, onClick) {
    return React.createElement(NavLinkWithScroll, { className: className ? className : combineClassNames(styles.linkText, styles.loggedIn), to: "/kirjautunut", title: name, onClick: onClick }, name);
}
export const LogIn = (props) => {
    const { isLoggedIn, name } = useAppSelector((state) => state.auth);
    const { className, isMobile, onLoggedInClick, loginLink } = props;
    const i18nState = useAppSelector((state) => state.i18n);
    const location = useLocation();
    const getLoginButton = (classNameProp) => {
        // Pass post-login URI only when logging from landingpage, validate it in BE
        const linkWithOptParams = location.pathname === i18nState.pageSlug
            ? `${loginLink.to}&${SSO_POSTLOGIN_URL}=${location.pathname}`
            : loginLink.to;
        return (React.createElement("a", { href: linkWithOptParams, className: combineClassNames(classNameProp, styles.linkText) }, loginLink.caption));
    };
    return (React.createElement(HorizontalContainer, { className: styles.container },
        React.createElement(Icon, { type: "icon-profile", size: isMobile ? '24' : '18' }),
        isLoggedIn ?
            getLink(className, name || '', onLoggedInClick)
            :
                getLoginButton(className)));
};

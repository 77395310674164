import React, { useEffect } from 'react';
import { getProductListProduct } from '../../../util/products.js';
import { clearProductRecommendationData, productRecommendationEvent } from '../../../reducers/analyticsSlice.js';
import { Accordion } from '../../../components/Accordion.js';
import { HorizontalContainer } from '../../../components/FlexboxContainer.js';
import { ProductCard } from '../../../components/ProductCard.js';
import styles from './style.scss';
export const ProductRecommendationsContainer = ({ recommendations, parentData, collapsed, toggleCollapse, }) => {
    var _a;
    const [productData, setProductData] = React.useState((_a = recommendations.products) === null || _a === void 0 ? void 0 : _a.map((p, i) => ({
        productCardData: getProductListProduct(p),
        analyticsData: mapProductToAnalyticsData(p, i, parentData),
    })));
    const [didMount, setDidMount] = React.useState(false);
    useEffect(() => {
        if (recommendations.products) {
            // First clear previous e-commerce object
            clearProductRecommendationData({ ecommerce: null });
            let productCardData = productData;
            if (didMount || !productCardData) {
                productCardData = recommendations.products.map((p, i) => ({
                    productCardData: getProductListProduct(p),
                    analyticsData: mapProductToAnalyticsData(p, i, parentData),
                }));
                setProductData(productCardData);
            }
            else {
                setDidMount(true);
            }
            productRecommendationEvent('view_item_list')(productCardData.map((p) => p.analyticsData))(parentData);
        }
    }, [recommendations.products]);
    if (!productData) {
        return React.createElement(React.Fragment, null);
    }
    const id = `product-${parentData.item_list_title}-list-${parentData.item_list_id}`;
    const heading = parentData.item_list_title === 'substitute' ? 'Tutustu myös näihin' : 'Muut ostivat myös';
    return (React.createElement(Accordion, { collapsed: collapsed, id: id, title: heading, toggleCollapse: toggleCollapse, "aria-label": "Toggle product recommendations list" },
        React.createElement(HorizontalContainer, { verticalAlign: "stretch", wrap: true, className: styles.productList, "data-pw": "productRecommendationsList" }, productData === null || productData === void 0 ? void 0 : productData.map((s) => {
            const onClickHandler = createOnClickHandler(s, parentData);
            return (React.createElement(ProductCard, { card: s.productCardData, cardCount: productData.length || 0, key: s.productCardData.id, onProductClicked: onClickHandler }));
        }))));
};
const mapProductToAnalyticsData = (p, i, parentData) => {
    const { item_list_name, item_list_id } = parentData;
    const categoryNames = Array.from({ length: 4 }, (_, index) => { var _a, _b, _c; return (_c = (_b = (_a = p.categories) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ''; });
    return {
        item_id: p.ean,
        item_name: p.name,
        index: i,
        item_brand: p.brand,
        item_category: categoryNames[0],
        item_category2: categoryNames[1],
        item_category3: categoryNames[2],
        item_category4: categoryNames[3],
        item_list_id,
        item_list_name,
    };
};
const createOnClickHandler = (s, parentData) => () => {
    clearProductRecommendationData({ ecommerce: null });
    productRecommendationEvent('select_item')([s.analyticsData])(parentData);
};

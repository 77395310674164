import React from 'react';
import * as R from 'ramda';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../hooks.js';
import * as statics from '../../staticData.js';
import { routes as routeConstructor, RouteType } from '../../routes.js';
import { IEDeprecatedBanner } from '../../components/Footer/IEDeprecatedBanner.js';
import { Helmet } from 'react-helmet';
import { mapLocalizedSlugsToLanguageNavigationItems } from '../../../common/localizedRouteHandlers.js';
import { Footer } from '../../components/Footer/Footer.js';
import { FragmentScroll } from '../../components/FragmentScroll.js';
import { Header } from '../../components/Header/Header.js';
import style from './style.scss';
function renderRoutes(routes) {
    return routes.map((route) => {
        switch (route.type) {
            case RouteType.redirect:
                return React.createElement(Redirect, { key: route.key, exact: route.exact, from: route.path, to: route.to });
            case RouteType.wrapper:
                return (React.createElement(Route, { path: route.path, exact: route.exact, key: `${route.key}-route`, render: () => (React.createElement(Switch, null, renderRoutes(route.childRoutes))) }));
            case RouteType.component: {
                const Component = route.component;
                return (React.createElement(Route, { path: route.path, exact: route.exact, key: `${route.key}-route`, render: (props) => (React.createElement(Component, Object.assign({}, props), route.childRoutesOpt && React.createElement(Switch, null, renderRoutes(route.childRoutesOpt)))) }));
            }
            default: {
                const _exhaustiveCheck = route;
                return React.createElement("div", { key: _exhaustiveCheck });
            }
        }
    });
}
const memoizeRenderRoutes = R.memoizeWith(() => 'renderRoutes', renderRoutes);
export const BasePage = ({ location, match, }) => {
    const routes = routeConstructor();
    const language = match.params.language || 'fi';
    const footerStrings = Object.assign(Object.assign({}, statics.translations[language].common), statics.translations[language].footer);
    const primaryNavigationItems = statics.navigationItems.primary[language];
    const secondaryNavigationItems = statics.navigationItems.secondary[language];
    const buttonNavigationItems = statics.navigationItems.buttons[language];
    const quickLinks = statics.quickLinks[language];
    const i18nState = useAppSelector((state) => state.i18n);
    const languageItems = mapLocalizedSlugsToLanguageNavigationItems(location, i18nState);
    return (React.createElement("div", { style: { position: 'relative' } },
        React.createElement(Helmet, { htmlAttributes: { lang: language } }),
        React.createElement(FragmentScroll, { hash: location.hash },
            React.createElement("div", null,
                React.createElement(Header, { language: language, languageItems: languageItems, primaryNavigationItems: primaryNavigationItems, secondaryNavigationItems: secondaryNavigationItems, buttonNavigationItems: buttonNavigationItems, pathName: location.pathname }),
                React.createElement("main", { className: style.basepageContent },
                    React.createElement(Switch, null, memoizeRenderRoutes(routes))),
                React.createElement(Footer, { location: location, languageItems: languageItems, strings: footerStrings, language: language, quickLinks: quickLinks }),
                React.createElement(IEDeprecatedBanner, null)))));
};

import { useEffect } from 'react';
import qs from 'query-string';
import { languageToLocale } from '../../common/apiTypes.js';
import { useGetCategoryHierarchyQuery, useGetLandingPageQuery } from '../services/api.js';
import { LandingPage } from './LandingPage/LandingPage.js';
import { useAppDispatch, useAppSelector } from '../hooks.js';
import { setI18nSlugsForLandingPages } from '../reducers/i18nSlice.js';
import { renderRtkQueryResult } from '../util/asyncData.js';
import { BYPASS_CACHE, PREVIEW } from '../../common/queryStrings.js';
import style from './LandingPagesWrapper.scss';
export const LandingPageWrapper = (props) => {
    var _a, _b;
    useGetCategoryHierarchyQuery();
    const dispatch = useAppDispatch();
    const { abCookie } = useAppSelector((state) => state.analytics);
    const { location } = props.routeProps;
    const queryParams = qs.parse(location.search);
    const searchParams = {
        slug: props.landingPage || props.routeProps.match.params.landingPage,
        locale: languageToLocale(props.language),
        bypassCache: (_a = queryParams[BYPASS_CACHE]) === null || _a === void 0 ? void 0 : _a.toString(),
        preview: (_b = queryParams[PREVIEW]) === null || _b === void 0 ? void 0 : _b.toString(),
        abCookie,
    };
    const useQueryResult = useGetLandingPageQuery(searchParams);
    useEffect(() => {
        if (useQueryResult.data) {
            dispatch(setI18nSlugsForLandingPages(useQueryResult.data));
        }
    }, [useQueryResult.data]);
    return renderRtkQueryResult(LandingPage, { language: props.language, routeProps: props.routeProps, abCookie }, useQueryResult, (elementProps, data) => (Object.assign(Object.assign({}, elementProps), { page: data })), { loadingClassName: style.loadingIndicator, language: props.language, fetch404PageFromContentful: true });
};

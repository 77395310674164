// The value will be replaced by rollup with the corresponding value from the build environment
// See the configuration for the replace module in rollup config
// The typing for the process global comes from the nodejs typings (even though we aren't in nodejs land here),
// so that's where you'll want to look if the typigns start complaining.
export const baseUrl = process.env.PAGE_BASE_URL;
export const kesproNetBaseUrl = process.env.KESPRO_NET_BASE_URL;
export const analyticsEnvironment = process.env.ANALYTICS_ENVIRONMENT;
export const deployEnvironment = process.env.DEPLOY_ENV;
export const feedbackly = {
    embedId: process.env.FEEDBACKLY_EMBED_ID || '',
    oid: process.env.FEEDBACKLY_OID || '',
    dmn: process.env.FEEDBACKLY_DMN || '',
};
// Feature flags
export const newProductPageEnabled = process.env.NEW_PRODUCT_PAGE_ENABLED === 'true';

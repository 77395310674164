import React from 'react';
import { useAppSelector } from '../hooks.js';
import { CardStack } from '@kesko/kespro-customer-card';
import style from './TukkukorttiWrapper.scss';
import { renderLandingPageElement } from './LandingPage/LandingPage.js';
export const TukkukorttiWrapper = ({ content, noCardsFallback, noLoginFallback, language, isLoggedIn }) => {
    if (!isLoggedIn) {
        return renderLandingPageElement(noLoginFallback, language, isLoggedIn);
    }
    const tukkukorttiCustomersRaw = useAppSelector((state) => state.auth.tukkukorttiCustomers);
    const tukkukorttiCustomers = tukkukorttiCustomersRaw === null || tukkukorttiCustomersRaw === void 0 ? void 0 : tukkukorttiCustomersRaw.reduce((customers, c) => {
        var _a, _b;
        // customer ID is a must for the barcode
        if (c.customer_id) {
            customers.push({
                company_name: (_a = c.company_name) !== null && _a !== void 0 ? _a : '',
                customer_id: c.customer_id,
                marketing_name: (_b = c.marketing_name) !== null && _b !== void 0 ? _b : '',
            });
        }
        return customers;
    }, []);
    if (!tukkukorttiCustomers || tukkukorttiCustomers.length === 0) {
        if (noCardsFallback) {
            return renderLandingPageElement(noCardsFallback, language, isLoggedIn);
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    }
    return (React.createElement("div", { className: style.tukkukorttiWrapper },
        renderLandingPageElement(content, language, isLoggedIn),
        React.createElement("div", { className: style.cardstackWrapper },
            React.createElement(CardStack, { customerData: tukkukorttiCustomers }))));
};
